// button.mfp-close {
// width: 43px;
// height: 43px;
// right: var(--space-s);
// top: var(--space-s);
// opacity: 1;
// }
.mfp-figure {
    figcaption {
        font-style: normal;
    }
}
img.mfp-img {
    margin: 60px auto 0;
}
.mfp-close {
    width: 28px;
    height: 28px;
    right: var(--space-m);
    top: var(--space-m);
    line-height: 1;
}
.mfp-preloader {
    font-family: $family-secondary;
}
.mfp-title {
    text-align: center;
    font-size: var(--step--2);
    line-height: 1.1;
}
.mfp-counter {
    font-size: var(--step--3);
    line-height: unset;
    font-family: $family-secondary;
}
.mfp-arrow {
    opacity: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
    &::after {
        display: none;
    }
    &::before {
        border-top-width: 0;
        border-bottom-width: 0;
        width: 17px;
        height: 26px;
        opacity: 1;
        position: static;
        margin: 0;
        border: 0;
    }
}
.mfp-arrow-left {
    &:before {
        border-right: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 2 2 14l12 12.004' stroke='%23000' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
}
.mfp-arrow-right {
    &:before {
        border-left: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2 2 12 12L2 26.004' stroke='%23000' stroke-width='3' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
}
