.grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    @include tablet {
        grid-template-columns: repeat(3, 1fr);
    }
    @include desktop {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(4, 1fr);
        a:nth-child(4),
        a:nth-child(8) {
            grid-row: span 2;
            grid-column: span 2;
        }
    }
    img {
        width: 100%;
        height: 100%;
        transition: opacity 0.125s ease-in;
    }
    a:hover,
    a:focus {
        img {
            opacity: 0.85;
        }
    }
}
