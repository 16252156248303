.slider-container {
    .js & {
        margin-bottom: 5.5rem;
        height: var(--fluid-555-1000);
    }
}
.slider {
    position: relative;
    .js & {
        height: var(--fluid-555-1000);
        // millora càrrega de l'slider
        opacity: 0;
        &.slick-initialized {
            opacity: 1;
        }
    }
    .slick-list,
    .slick-track {
        height: 100%;
    }
    .slick-list:has(.slick-slide:only-child) {
        overflow: visible;
    }
    .slider__picture {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        .wrapper {
            z-index: 2;
            @include tablet {
                padding: 0 var(--space-3xl);
            }
            @media screen and (min-width: 1400px) {
                padding: 0 var(--space-l);
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 50%;
            background: linear-gradient(to right, black, transparent);
            opacity: 0.6;
            z-index: 1;
        }
    }
    .slider__video {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 80%;
            background: linear-gradient(to right, black, transparent);
            opacity: 0.6;
            z-index: 1;
        }
        .wrapper {
            z-index: 2;
            @include tablet {
                padding: 0 var(--space-3xl);
            }
            @media screen and (min-width: 1400px) {
                padding: 0 var(--space-l);
            }
        }
    }
        
    .slick-slide {
        > div {
            height: 100%;
            > div {
                height: 100%;
            }
        }
    }
    .slick-arrow {
        width: 48px;
        height: 48px;
        z-index: 1;
        @include mobile {
            display: none !important;
        }
        &:focus {
            top: 50%;
            span {
                margin-left: 0;
            }
        }
        span {
            opacity: 1;
            &::before {
                content: "";
                width: 48px;
                height: 48px;
                display: block;
                // margin: 0 auto;
            }
        }
    }
    .slick-prev {
        left: var(--space-m);
    }
    .slick-next {
        right: var(--space-m);
    }
    .slick-prev-icon::before {
        background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='matrix(-1 0 0 1 48 0)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23FFF' cx='24' cy='24' r='24'/%3E%3Cpath fill='%23000' d='M20.8 12 18 14.798l9.198 9.2-9.198 9.2L20.8 36l11.998-12.002z'/%3E%3C/g%3E%3C/svg%3E");
    }
    .slick-next-icon::before {
        background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23FFF' cx='24' cy='24' r='24'/%3E%3Cpath fill='%23000' d='M20.8 12 18 14.798l9.198 9.2-9.198 9.2L20.8 36l11.998-12.002z'/%3E%3C/g%3E%3C/svg%3E");
    }
    .slick-dots {
        position: static;
        margin-top: var(--space-m);
        li {
            margin: 0 0.75rem;
            button {
                margin: 0;
            }
            .slick-dot-icon {
                opacity: 1;
                &::before {
                    width: 14px;
                    height: 14px;
                    border: 2px solid $primary;
                    border-radius: 50%;
                    color: transparent;
                }
            }
            &.slick-active button .slick-dot-icon:before {
                margin-top: 0;
                margin-left: 0;
                font-size: inherit;
                background-color: $primary;
            }
        }
    }
    // &__text {
    //     position: absolute;
    //     bottom: var(--space-m);
    //     h1,
    //     h2 {
    //         transition: background-color 0.125s ease-in;
    //         @include mobile {
    //             max-width: 15ch;
    //         }
    //         @include tablet {
    //             max-width: 18ch;
    //         }
    //         &:has(a:hover) {
    //             background: rgba(255, 255, 255, 0.8) !important;
    //         }
    //     }
    //     p {
    //         margin-top: 2px;
    //         width: fit-content;
    //     }
    //     a {
    //         text-decoration: none;
    //     }
    //     &.has-background-white {
    //         @include mobile {
    //             position: static;
    //         }
    //         @include tablet {
    //             max-width: 40ch;
    //             bottom: auto;
    //             top: -4rem;
    //         }
    //         @include desktop {
    //             max-width: 45ch;
    //         }
    //         p {
    //             margin-top: var(--space-s);
    //         }
    //         + p {
    //             margin-top: 2px;
    //             width: fit-content;
    //         }
    //     }
    //     &-container {
    //         @include tablet {
    //             max-width: 91rem;
    //             margin-left: auto;
    //             margin-right: auto;
    //             padding-left: var(--space-l);
    //             padding-right: var(--space-l);
    //         }
    //     }
    // }
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     width: 50%;
    //     background: linear-gradient(to right, black, transparent);
    //     opacity: 0.4;
    //     z-index: 1;
    // }
    &__btn {
        display: inline-block;
        background-color: #fff;
        color: #000;
        font-family: $family-secondary;
        font-weight: 600;
        font-size: var(--step-2);
        padding: var(--space-s) var(--space-m);
        border-radius: $radius;
        text-decoration: none;
    }

    

}
