// #gdpr-cookie-message {
//     position: fixed;
//     right: 0;
//     bottom: var(--space-l);
//     max-width: 37rem;
//     // opacity: 0.9;
//     box-shadow:
//         0px 0px 0.6px rgba(0, 0, 0, 0.02),
//         0px 0px 2.4px rgba(0, 0, 0, 0.028),
//         0px 0px 6.3px rgba(0, 0, 0, 0.035),
//         0px 0px 14px rgba(0, 0, 0, 0.042),
//         0px 0px 30.6px rgba(0, 0, 0, 0.05),
//         0px 0px 80px rgba(0, 0, 0, 0.07);
//     z-index: 9999;
//     [role="list"] {
//         list-style: none;
//     }
//     @include mobile {
//         top: 0;
//         bottom: auto;
//         max-width: none;
//     }
// }

/* Cookie Dialog */
#gdpr-cookie-message {
    background-color: #fff;
    opacity: 0.9;
    padding: 30px 35px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
    // margin-left: 30px;
    @include desktop {
        position: fixed;
        z-index: 9999;
        right: 0;
        bottom: 60px;
        max-width: 450px;
    }
}
#gdpr-cookie-message hr {
    margin: 10px 0;
}
#gdpr-cookie-message h2 {
    // font-weight: bold;
}
// #gdpr-cookie-message h4 {
//     color: #000;
//     font-size: 18px;
//     font-weight: bolder;
//     margin-bottom: 10px;
// }
#gdpr-cookie-message h5 {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: none;
}
// #gdpr-cookie-message p,
// #gdpr-cookie-message ul {
// color: #000;
// font-size: var(--step--1);
// line-height: 1.5em;
// margin: 0 0 1.125rem;
// }
#gdpr-cookie-message ul {
    li {
        padding-left: 0 !important;
        &::before {
            display: none;
        }
    }
}
#gdpr-cookie-message p:last-child {
    margin-bottom: 0;
    text-align: right;
}
#gdpr-cookie-message li {
    width: 49%;
    display: inline-block;
    margin-bottom: 1em;
}
// #gdpr-cookie-message label {
//     margin-left: 0 !important;
//     margin-right: 0 !important;
//     display: inherit !important;
// }

#gdpr-cookie-message a {
    // color: #000000 !important;
    // text-decoration: none;
    // font-size: 15px;
    font-weight: bold;
    color: #000;
    // padding-bottom: 2px;
    // border-bottom: 1px dotted rgba(255, 255, 255, 0.75);
    // transition: all 0.3s ease-in;
}
// #gdpr-cookie-message button {
//     white-space: normal;
// }
// #gdpr-cookie-message a:hover {
//     color: white;
//     border-bottom-color: #000000;
//     transition: all 0.3s ease-in;
// }
// #gpdr-cookie-message a:focus {
//     outline: none !important;
// }
// #gdpr-cookie-message button,
// button#ihavecookiesBtn {
// font-size: 14px;
// padding: 7px 20px;
// margin-left: 0;
// cursor: pointer;
// transition: all 0.3s ease-in;
// border-radius: 5px;
// width: 100%;
// margin-bottom: 8px;
// }
// #gdpr-cookie-message button#gdpr-cookie-accept,
// #gdpr-cookie-message button#gdpr-cookie-reject {
// background-color: #000000;
// color: #fff;
// border: none;
// }
// #gdpr-cookie-message button#gdpr-cookie-accept:hover,
// #gdpr-cookie-message button#gdpr-cookie-reject:hover {
// transition: all 0.3s ease-in;
// opacity: 0.7;
// }
// #gdpr-cookie-message button#gdpr-cookie-advanced {
// background: none;
// border: 1px solid #000;
// color: #000;
// }

// #gdpr-cookie-message button:disabled {
//     // opacity: 0.3;
// }
// #gdpr-cookie-message input[type="checkbox"] {
//     float: none;
//     margin-top: 0;
//     margin-right: 5px;
//     background: #e0e0e0;
//     border-radius: 4px;
//     width: 24px;
//     height: 24px;
//     opacity: 0.9;
//     border: none;
//     vertical-align: middle;
//     margin-bottom: 0;
// }
// #gdpr-cookie-message input[type="checkbox"]:focus {
//     outline: none !important;
// }
// #gdpr-cookie-message input[type="checkbox"]:hover {
//     background: #000;
//     cursor: pointer;
//     border: 0;
// }
// #gdpr-cookie-message input[type="checkbox"]:checked {
//     background: #000;
// }
// #gdpr-cookie-message input[type="checkbox"]:disabled {
//     background: #979197;
// }
// #gdpr-cookie-message input[type="checkbox"]:disabled:hover {
//     background: #979197;
//     cursor: default !important;
// }
// #gdpr-cookie-message input[type="checkbox"]:checked:after {
//     content: "";
//     background: url("img/check-ico-cookies.png") no-repeat;
//     width: 17px;
//     height: 14px;
//     position: relative;
//     top: 12px;
//     left: 12px;
//     display: block;
// }

// @media (max-width: 490px) {
//     #gdpr-cookie-message li {
//         width: 100%;
//     }

//     #gdpr-cookie-message button {
//         float: left;
//     }
//     .isiOS.isiPhone #gdpr-cookie-message {
//         padding: 10px 20px;
//     }
// }
