.cercador {
    article {
        padding-bottom: var(--space-l);
        border-bottom: 1px solid #000;
        > * + * {
            margin-top: var(--space-xs);
        }
        a {
            color: #000;
        }
    }
    strong {
        background-color: #ffc107;
        // color: #fff;
        font-weight: inherit;
        padding-left: 0.25em;
        padding-right: 0.25em;
    }
}
.cerca-titol-element {
    font-weight: 500;
    line-height: 1.3;
}
