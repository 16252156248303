.intro-text {
    a {
        color: $red;
        text-decoration: none;
        &:hover {
            text-decoration: underline 0.05em;
            text-underline-offset: 0.15em;
        }
    }
}
