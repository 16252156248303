.highlighted-p-2 {
    font-weight: 100;
    font-size: 22px;
    line-height: 1.4;
    text-align: center;
    max-width: 60ch !important;
    margin-left: auto;
    margin-right: auto;
    text-wrap: balance;
}
.text-base > .highlighted-p-2,
.text-base > .column:not(.is-half-tablet) > .highlighted-p-2:not(:only-child) {
    @include desktop {
        width: 68ch;
        max-width: 68ch !important;
        margin-left: 0;
        margin-right: 0;
    }
}
