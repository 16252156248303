@use "node_modules/utopia-core-scss/src/utopia" as utopia;

/* @link https://utopia.fyi/type/calculator?c=320,16,1.067,1240,16,1.125,10,3,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
    @include utopia.generateTypeScale(
        (
            "minWidth": 320,
            "maxWidth": 1240,
            "minFontSize": 16,
            "maxFontSize": 16,
            "minTypeScale": 1.067,
            "maxTypeScale": 1.125,
            "positiveSteps": 10,
            "negativeSteps": 3,
            "prefix": "step-",
            "relativeTo": "viewport-width"
        )
    );
}
