.box-destacat {
    &::before {
        content: "";
        width: 6px;
        height: 70px;
        background-color: #000;
        display: block;
        position: absolute;
        left: 0;
    }
    h2 {
        hyphens: auto;
    }
    a {
        color: $text;
    }
}
