footer {
    background: url("img/pict-skyline-valls.svg") 11% 100% no-repeat;
    @include mobile {
        background-position-y: 80%;
    }
    a {
        color: $text;
    }
    > div:last-of-type {
        margin-top: 230px;
        a {
            color: $yellow;
        }

        > div > div {
            @include mobile {
                ul,
                li + li {
                    margin-top: var(--space-xs);
                }
            }
            @include tablet {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                ul {
                    display: flex;
                    flex-wrap: wrap;
                }
                li::before {
                    content: "·";
                    display: inline-block;
                    margin: 0 var(--space-s);
                }
            }
        }
    }
    address {
        &::after {
            content: "";
            display: block;
            height: 1px;
            width: 11rem;
            background-color: #000;
            margin: var(--space-l) auto;
        }
    }
}
