// .breadcrumb-container {
//     border-bottom: 1px solid #ddc6aa;
//     .bg-img & {
//         border-bottom-color: rgba(255, 255, 255, 0.2);
//     }
//     .has-background-grey-light & {
//         border-bottom-color: $grey;
//     }
//     body:has(.gallery) & {
//         border-bottom: none;
//         margin-bottom: 0 !important;
//     }
// }
.breadcrumb-nav {
    // @include tablet {
    //     &::before {
    //         content: "";
    //         display: block;
    //         width: 6.25rem;
    //         height: 1px;
    //         background: $primary;
    //         margin-bottom: var(--space-l);
    //     }
    // }
    ol {
        list-style-type: "";
        font-size: var(--fluid-12-24);
        line-height: 1.3;
    }
    li {
        display: inline;
        &::after {
            content: "\002F";
            padding-left: var(--space-3xs);
            padding-right: var(--space-3xs);
        }
        &:last-child::after {
            content: "";
        }
    }
    a {
        color: $primary;
    }
    .text-base & {
        ol {
            list-style: none;
            >li {
                padding-left: 0;
                &:before {
                    content: none;
                }
            }
        }

    }
}
