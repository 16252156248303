@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    src: url(../../fonts/Poppins-normal-200.ttf) format("truetype"),
        url(../../fonts/Poppins-normal-200.woff2) format("woff2"),
        url(../../fonts/Poppins-normal-200.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    src: url(../../fonts/Poppins-normal-300.woff2) format("woff2"),
        url(../../fonts/Poppins-normal-300.ttf) format("truetype"),
        url(../../fonts/Poppins-normal-300.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: url(../../fonts/Poppins-normal-400);
    src: local("Poppins"),
        url(../../fonts/Poppins-normal-400.woff2) format("woff2"),
        url(../../fonts/Poppins-normal-400.ttf) format("truetype"),
        url(../../fonts/Poppins-normal-400?#iefix) format("embedded-opentype"),
        url(../../fonts/Poppins-normal-400.svg#Poppins) format("svg"),
        url(../../fonts/Poppins-normal-400.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    src: url(../../fonts/Poppins-normal-500.woff2) format("woff2"),
        url(../../fonts/Poppins-normal-500.ttf) format("truetype"),
        url(../../fonts/Poppins-normal-500.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: url(../../fonts/Poppins-normal-600.woff2) format("woff2"),
        url(../../fonts/Poppins-normal-600.ttf) format("truetype"),
        url(../../fonts/Poppins-normal-600.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    src: url(../../fonts/Poppins-normal-700.ttf) format("truetype"),
        url(../../fonts/Poppins-normal-700.woff) format("woff"),
        url(../../fonts/Poppins-normal-700.woff2) format("woff2");
    font-display: swap;
}
