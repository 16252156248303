.wrapper {
    max-width: 78rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--space-l);
    padding-right: var(--space-l);
    &--large {
        max-width: 104rem;
        @include desktop {
            max-width: 100rem;
            padding: 0;
        }
    }
    &--narrow {
        max-width: 61.5rem;
    }
    // &--narrower {
    //     max-width: 69rem;
    // }
}
