.has-submenu {
    transition-duration: 0.5s;
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    // @include desktop {
    //     flex-wrap: nowrap;
    // }
    &.open {
        // > a {
        // position: relative;
        // @include desktop {
        //     &::after {
        //         content: "";
        //         display: block;
        //         width: 10px;
        //         height: 10px;
        //         background-color: $primary;
        //         transform: rotate(-45deg);
        //         top: 88px;
        //         position: absolute;
        //     }
        // }
        // }
        // > ul {
        .dropdown {
            display: block;
            z-index: 2;
            opacity: 1;
            // @include desktop {
            //     display: flex;
            //     justify-content: center;
            // }
            ul ul {
                @include touch {
                    display: none;
                }
            }
        }
    }
    button {
        background: transparent;
        border: 0;
        padding: 0;
        width: 40px;
        height: 40px;
        margin-left: 0.5rem;
        // cursor: pointer;
        // @include until-widescreen {
        margin-left: auto;
        flex-shrink: 0;
        // }
        > span {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            // box-sizing: content-box;
            justify-content: center;
            align-items: center;
            // background: aqua;
            &::after {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                border: solid $primary;
                border-width: 0 0 1px 1px;
                transform: rotate(-45deg);
                position: relative;
                @include mobile {
                    top: -6px;
                }
                @include desktop {
                    width: 6px;
                    height: 6px;
                    transform: rotate(-45deg) scale(1.5); /* augmenta mida del botó*/
                }
            }
        }
        &[aria-expanded="true"] {
            span::after {
                transform: rotate(135deg);
                top: 5px;
                @include desktop {
                    transform: rotate(135deg) scale(1.5);
                }
            }
        }
        &:hover {
            opacity: 0.6;
        }
    }
}

.dropdown {
    flex-basis: 100%;
    a {
        color: $text;
    }
    .js & {
        transition: all 0.5s ease;
        display: none;
        opacity: 0;
    }
    @include touch {
        padding: var(--space-s) 0 var(--space-s) var(--space-s);
        li + li {
            margin-top: var(--space-s);
        }
    }
    @include desktop {
        .js & {
            background-color: #fff;
            position: absolute;
            left: 0;
            right: 0;
            top: 170%;
            &::before,
            &::after {
                content: "";
                display: block;
                background-color: inherit;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 200%;
            }
            &::before {
                right: 100%;
            }
            &::after {
                left: 100%;
            }
        }
        padding: var(--space-2xl) 0;
        a:hover {
            text-decoration: underline;
        }
        p {
            &::after {
                content: "";
                display: block;
                width: 3.125rem;
                height: 1px;
                background-color: #000;
                margin-top: var(--space-m);
            }
        }
    }
}
