/* Override Bulma icon margin styles */
// .button .icon:first-child {
//     margin: 0 0.5em 0 0 !important;
// }

.button {
    // height: auto;
    // color: $button-color;
    font-weight: 500;
    text-decoration: none;
    // gap: var(--space-3xs);
    font-size: var(--step--1);
    // display: flex;
    // max-width: fit-content;
    // redefinim el color de l'outline, ja que per defecte és 'currentColor' i no es veu quan el color de text és blanc.
    // &:focus,
    // &:focus-visible {
    //     outline-color: $black !important;
    // }
    // white-space: normal;
    // &:hover,
    // &:focus {
    //     color: $button-background-color;
    //     background-color: #fff;
    // }
    // &.is-small {
    //     font-weight: normal;
    //     font-size: var(--step--2);
    //     padding: 0.25rem 1rem;
    //     height: 2.1875rem;
    // }
    &[href$=".pdf"]::before {
        flex-shrink: 0;
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20'%3E%3Cpath d='M6 2L6 14L6 2ZM2 20C1.45 20 0.97899997 19.804001 0.58700001 19.413C0.19599999 19.021 0 18.549999 0 18L0 4L2 4L2 18L16 18L16 20L2 20ZM6 14L18 14L18 2L6 2L6 14ZM6 16C5.4499998 16 4.9790001 15.804 4.5869999 15.413C4.1960001 15.021 4 14.55 4 14L4 2C4 1.45 4.1960001 0.97899997 4.5869999 0.58700001C4.9790001 0.19599999 5.4499998 0 6 0L18 0C18.549999 0 19.021 0.19599999 19.413 0.58700001C19.804001 0.97899997 20 1.45 20 2L20 14C20 14.55 19.804001 15.021 19.413 15.413C19.021 15.804 18.549999 16 18 16L6 16ZM15 10.5L16 10.5L16 8.5L17 8.5L17 7.5L16 7.5L16 6.5L17 6.5L17 5.5L15 5.5L15 10.5ZM12 9.5L13 9.5L13 6.5L12 6.5L12 9.5ZM11 10.5L13 10.5C13.283 10.5 13.521 10.404 13.712 10.212C13.904 10.021 14 9.783 14 9.5L14 6.5C14 6.217 13.904 5.9790001 13.712 5.7880001C13.521 5.5960002 13.283 5.5 13 5.5L11 5.5L11 10.5ZM8 7.5L9 7.5L9 6.5L8 6.5L8 7.5ZM7 10.5L8 10.5L8 8.5L9 8.5C9.283 8.5 9.5209999 8.4040003 9.7119999 8.2119999C9.9040003 8.0209999 10 7.783 10 7.5L10 6.5C10 6.217 9.9040003 5.9790001 9.7119999 5.7880001C9.5209999 5.5960002 9.283 5.5 9 5.5L7 5.5L7 10.5Z' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        margin-right: 12px;
    }
    &--audio::before {
        flex-shrink: 0;
        content: "";
        display: block;
        width: 22px;
        height: 22px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22'%3E%3Cpath d='M22 11v7.333c0 1.009-.36 1.872-1.078 2.589-.717.719-1.58 1.078-2.589 1.078a3.666 3.666 0 0 1-3.666-3.667V16.5a3.666 3.666 0 0 1 3.666-3.667h.917V11c0-2.292-.801-4.24-2.407-5.845C15.239 3.553 13.292 2.75 11 2.75s-4.24.803-5.845 2.405C3.551 6.76 2.75 8.708 2.75 11v1.833h.917A3.666 3.666 0 0 1 7.333 16.5v1.833A3.666 3.666 0 0 1 3.667 22c-1.009 0-1.872-.36-2.589-1.078C.359 20.205 0 19.342 0 18.333V11c0-3.056 1.069-5.652 3.208-7.792C5.348 1.068 7.944 0 11 0s5.652 1.069 7.792 3.208C20.932 5.348 22 7.944 22 11' fill-rule='evenodd'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        margin-right: 12px;
    }
    &:has(img) img {
        margin-left: 12px;
    }
}
p.button {
    // &:hover,
    // &:focus {
    //     background-color: #fff;
    //     a {
    //         color: $button-background-color;
    //     }
    // }
    a {
        // color: $button-color;
        text-decoration: none;
    }
}
button,
input {
    &.button {
        color: #fff;
        background-color: $blue;
        border: 0;
        padding-left: var(--space-xl);
        padding-right: var(--space-xl);
        &:hover,
        &:focus {
            color: #fff;
            background-color: darken($blue, 5%);
        }
    }
}
// Botons un a continuació d'un altre
p.button:has(+ p.button),
p.button + p.button {
    display: flex;
    max-width: fit-content;
}

p.btn {
    @extend .button;
    a {
        color: #000;
    }
}
