.download-doc {
    // @extend .highlighted-link;
    a {
        display: inline-flex;
        gap: var(--space-xs);
        align-items: flex-start;
        color: $blue;
        // border: 1px solid $grey;
        // font-size: var(--step--1);
        // padding: var(--space-s);
        text-decoration: none;
        font-weight: normal;
        // .columns2 & {
        //     width: 100%;
        // }
        &:hover,
        &:focus {
            text-decoration: underline;
        }
        // &::after {
        //     content: "";
        //     width: 20px;
        //     height: 20px;
        //     background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000' fill-rule='nonzero'%3E%3Cpath d='M.625 18.125h18.75v1.25H.625zM15.442 11.692l-.884-.884-3.621 3.62V.625h-1.25v13.803l-3.621-3.62-.884.884 5.129 5.129 5.129-5.129z'/%3E%3C/g%3E%3C/svg%3E");
        // }
        &::before {
            flex-shrink: 0;
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.847 20h8v-2h-8v2Zm5-9.975h-2V13.2l-1.6-1.6-1.4 1.4 4 4 4-4-1.4-1.4-1.6 1.6v-3.175ZM2.847 14h6v2h-6c-.55 0-1.021-.196-1.412-.587A1.927 1.927 0 0 1 .847 14V2c0-.55.196-1.021.588-1.412A1.923 1.923 0 0 1 2.847 0h9l4 4v4.025h-2V5h-3V2h-8v12Z' fill='%23020203' fill-rule='evenodd'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: 50% 50%;
            position: relative;
            top: 4px;
        }
        &[href$=".pdf"] {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20'%3E%3Cpath d='M6 2L6 14L6 2ZM2 20C1.45 20 0.97899997 19.804001 0.58700001 19.413C0.19599999 19.021 0 18.549999 0 18L0 4L2 4L2 18L16 18L16 20L2 20ZM6 14L18 14L18 2L6 2L6 14ZM6 16C5.4499998 16 4.9790001 15.804 4.5869999 15.413C4.1960001 15.021 4 14.55 4 14L4 2C4 1.45 4.1960001 0.97899997 4.5869999 0.58700001C4.9790001 0.19599999 5.4499998 0 6 0L18 0C18.549999 0 19.021 0.19599999 19.413 0.58700001C19.804001 0.97899997 20 1.45 20 2L20 14C20 14.55 19.804001 15.021 19.413 15.413C19.021 15.804 18.549999 16 18 16L6 16ZM15 10.5L16 10.5L16 8.5L17 8.5L17 7.5L16 7.5L16 6.5L17 6.5L17 5.5L15 5.5L15 10.5ZM12 9.5L13 9.5L13 6.5L12 6.5L12 9.5ZM11 10.5L13 10.5C13.283 10.5 13.521 10.404 13.712 10.212C13.904 10.021 14 9.783 14 9.5L14 6.5C14 6.217 13.904 5.9790001 13.712 5.7880001C13.521 5.5960002 13.283 5.5 13 5.5L11 5.5L11 10.5ZM8 7.5L9 7.5L9 6.5L8 6.5L8 7.5ZM7 10.5L8 10.5L8 8.5L9 8.5C9.283 8.5 9.5209999 8.4040003 9.7119999 8.2119999C9.9040003 8.0209999 10 7.783 10 7.5L10 6.5C10 6.217 9.9040003 5.9790001 9.7119999 5.7880001C9.5209999 5.5960002 9.283 5.5 9 5.5L7 5.5L7 10.5Z' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
            }
        }
        &[href$=".doc"],
        &[href$=".docx"] {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h20v20H0z'/%3E%3C/defs%3E%3Cg transform='translate(.731)' fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M15.231 10.664c.864 0 1.312-.512 1.312-1.407v-.744h-.832v.8c0 .399-.176.543-.456.543s-.456-.144-.456-.543V6.29c0-.4.176-.551.456-.551s.456.152.456.55v.6h.832v-.543c0-.895-.448-1.407-1.312-1.407-.863 0-1.311.512-1.311 1.407v2.91c0 .896.448 1.408 1.311 1.408Zm-3.6-1.351c0 .399.176.552.456.552s.456-.153.456-.552V6.29c0-.4-.176-.551-.456-.551s-.456.152-.456.55v3.024Zm-.879-.056V6.346c0-.895.472-1.407 1.335-1.407.864 0 1.335.512 1.335 1.407v2.91c0 .896-.471 1.408-1.335 1.408-.863 0-1.335-.512-1.335-1.407ZM8.935 9.8c.28 0 .448-.143.448-.543V6.346c0-.4-.168-.543-.448-.543h-.496V9.8h.496Zm.016.8H7.56V5.003h1.391c.879 0 1.311.488 1.311 1.383v2.83c0 .896-.432 1.384-1.311 1.384ZM6 14h12V2H6v12ZM20 2v12c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 18 16H6a1.93 1.93 0 0 1-1.413-.587A1.93 1.93 0 0 1 4 14V2c0-.55.196-1.021.587-1.412A1.927 1.927 0 0 1 6 0h12c.55 0 1.021.196 1.412.588C19.804.978 20 1.45 20 2ZM2 18h14v2H2a1.93 1.93 0 0 1-1.413-.587A1.93 1.93 0 0 1 0 18V4h2v14Z' fill='%23020203' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
        &[href$=".xls"],
        &[href$=".xlsx"] {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h20v20H0z'/%3E%3C/defs%3E%3Cg transform='translate(.462)' fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M15.075 10.664c.864 0 1.312-.512 1.312-1.407 0-.64-.232-1.104-.968-1.751-.576-.504-.752-.808-.752-1.216 0-.4.16-.551.44-.551s.44.152.44.55v.233h.831v-.176c0-.895-.439-1.407-1.295-1.407-.856 0-1.295.512-1.295 1.407 0 .64.232 1.104.967 1.752.576.503.752.807.752 1.215 0 .399-.176.543-.456.543s-.455-.144-.455-.543v-.4h-.832v.344c0 .895.448 1.407 1.311 1.407ZM13.514 9.8h-1.447V5.003h-.879V10.6h2.326v-.8Zm-5.143.8.72-2.215h.016l.712 2.215h.927l-.983-2.878.919-2.72h-.815l-.672 2.064h-.016l-.656-2.063h-.911l.92 2.719-.984 2.878h.823ZM6 14h12V2H6v12ZM20 2v12c0 .55-.196 1.02-.588 1.413A1.927 1.927 0 0 1 18 16H6a1.93 1.93 0 0 1-1.413-.587A1.926 1.926 0 0 1 4 14V2c0-.55.195-1.021.587-1.412A1.927 1.927 0 0 1 6 0h12c.55 0 1.02.196 1.412.588C19.804.978 20 1.45 20 2ZM2 18h14v2H2a1.93 1.93 0 0 1-1.413-.587A1.926 1.926 0 0 1 0 18V4h2v14Z' fill='%23020203' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
        &[href$=".zip"],
        &[href$=".rar"] {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h20v20H0z'/%3E%3C/defs%3E%3Cg transform='translate(.193)' fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M13.931 7.698h.416c.28 0 .432-.128.432-.528v-.84c0-.4-.152-.527-.432-.527h-.416v1.895Zm0 2.902h-.879V5.004h1.295c.879 0 1.311.487 1.311 1.382v.728c0 .896-.432 1.383-1.311 1.383h-.416V10.6Zm-2.399 0h.879V5.005h-.879V10.6Zm-.522-.8H9.339l1.671-4.013v-.783H8.515v.799h1.592L8.436 9.817v.783h2.574v-.8ZM6 14h12V2H6v12ZM20 2v12c0 .55-.196 1.02-.588 1.413A1.922 1.922 0 0 1 18 16H6c-.55 0-1.021-.195-1.413-.587A1.93 1.93 0 0 1 4 14V2c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 6 0h12c.55 0 1.021.196 1.412.588C19.804.98 20 1.45 20 2ZM2 18h14v2H2c-.55 0-1.021-.195-1.413-.587A1.93 1.93 0 0 1 0 18V4h2v14Z' fill='%23020203' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
        &[href$=".jpg"],
        &[href$=".jpeg"],
        &[href$=".png"],
        &[href$=".gif"],
        &[href$=".webp"],
        &[href$=".svg"] {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.578 14h12V2h-12v12Zm14-12v12a1.93 1.93 0 0 1-.587 1.413 1.93 1.93 0 0 1-1.413.587h-12c-.55 0-1.021-.196-1.412-.587A1.927 1.927 0 0 1 4.578 14V2c0-.55.196-1.021.588-1.412A1.923 1.923 0 0 1 6.578 0h12c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412Zm-6.45 5.5-2.3 3-1.55-2-2.7 3.5h10l-3.45-4.5ZM2.578 18h14v2h-14c-.55 0-1.021-.196-1.412-.587A1.927 1.927 0 0 1 .578 18V4h2v14Z' fill='%23020203' fill-rule='evenodd'/%3E%3C/svg%3E");
            }
        }
        &[href^="http"] {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath d='M11 0v2h3.6l-9.3 9.3 1.4 1.4L16 3.4V7h2V0h-7Zm5 9h2v7c0 .55-.196 1.021-.588 1.412A1.923 1.923 0 0 1 16 18H2c-.55 0-1.021-.196-1.412-.588A1.923 1.923 0 0 1 0 16V2C0 1.45.196.979.588.588A1.923 1.923 0 0 1 2 0h7v2H2v14h14V9Z' fill-rule='evenodd'/%3E%3C/svg%3E");
            }
        }
    }
}
