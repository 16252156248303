// Fitxer copiat de node_modules/bulma-scss/helpers/_typography.scss i modificat, perquè no generi classes tipus .is-size-5-mobile (ja no fan falta perquè s'utilitza el sistema Utopia).

// @import "../utilities/mixins";
@import "../../node_modules/bulma-scss/utilities/_mixins";

@mixin typography-size($target: "") {
    @each $size in $sizes {
        $i: index($sizes, $size);

        .is-size-#{$i}#{if($target == "", "", "-" + $target)} {
            font-size: $size !important;
        }
    }
}

@include typography-size;

// @include mobile {
//   @include typography-size("mobile");
// }

// @include tablet {
//   @include typography-size("tablet");
// }

// @include touch {
//   @include typography-size("touch");
// }

// @include desktop {
//   @include typography-size("desktop");
// }

// @include widescreen {
//   @include typography-size("widescreen");
// }

// @include fullhd {
//   @include typography-size("fullhd");
// }

// $alignments: ("centered": "center", "justified": "justify", "left": "left", "right": "right");
$alignments: (
    "centered": "center",
    "left": "left",
    "right": "right",
);

@each $alignment, $text-align in $alignments {
    .has-text-#{$alignment} {
        text-align: #{$text-align} !important;
    }
}

@each $alignment, $text-align in $alignments {
    @include mobile {
        .has-text-#{$alignment}-mobile {
            text-align: #{$text-align} !important;
        }
    }

    @include tablet {
        .has-text-#{$alignment}-tablet {
            text-align: #{$text-align} !important;
        }
    }

    @include tablet-only {
        .has-text-#{$alignment}-tablet-only {
            text-align: #{$text-align} !important;
        }
    }

    @include touch {
        .has-text-#{$alignment}-touch {
            text-align: #{$text-align} !important;
        }
    }

    @include desktop {
        .has-text-#{$alignment}-desktop {
            text-align: #{$text-align} !important;
        }
    }

    @include desktop-only {
        .has-text-#{$alignment}-desktop-only {
            text-align: #{$text-align} !important;
        }
    }

    @include widescreen {
        .has-text-#{$alignment}-widescreen {
            text-align: #{$text-align} !important;
        }
    }

    @include widescreen-only {
        .has-text-#{$alignment}-widescreen-only {
            text-align: #{$text-align} !important;
        }
    }

    @include fullhd {
        .has-text-#{$alignment}-fullhd {
            text-align: #{$text-align} !important;
        }
    }
}

.is-capitalized {
    text-transform: capitalize !important;
}

.is-lowercase {
    text-transform: lowercase !important;
}

.is-uppercase {
    text-transform: uppercase !important;
}

.is-italic {
    font-style: italic !important;
}

.is-underlined {
    text-decoration: underline !important;
}

.has-text-weight-thin {
    font-weight: 100 !important;
}
.has-text-weight-extralight {
    font-weight: $weight-extralight !important;
}
.has-text-weight-light {
    font-weight: $weight-light !important;
}

.has-text-weight-normal {
    font-weight: $weight-normal !important;
}

.has-text-weight-medium {
    font-weight: $weight-medium !important;
}

.has-text-weight-semibold {
    font-weight: $weight-semibold !important;
}

.has-text-weight-bold {
    font-weight: $weight-bold !important;
}

.is-family-primary {
    font-family: $family-primary !important;
}

.is-family-secondary {
    font-family: $family-secondary !important;
}

.is-family-sans-serif {
    font-family: $family-sans-serif !important;
}

.is-family-monospace {
    font-family: $family-monospace !important;
}

.is-family-code {
    font-family: $family-code !important;
}
