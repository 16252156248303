.related-docs {
    max-width: 50ch;
    @extend .download-doc;
    a {
        display: flex;
        justify-content: space-between;
        border: solid $primary;
        border-width: 0 0 1px;
        &::after {
            margin-left: auto;
        }
    }
    li {
        padding-left: 0 !important;
        margin-top: 0 !important;
        &::before {
            display: none !important;
        }
        &:last-child a {
            border-bottom: 0;
        }
    }
}
