.main-content {
    > .wrapper:first-child {
        &::before {
            content: "";
            display: block;
            width: 6.25rem;
            height: 1px;
            background: $primary;
            margin-bottom: var(--space-l);
        }
        @include mobile {
            &::before {
                margin-bottom: var(--space-xs);
            }
        }
    }
}
