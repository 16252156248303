.is-checkradio[type="checkbox"],
.is-checkradio[type="radio"] {
    display: inline-block;
    &:focus + label::before {
        @include focus;
    }
    + label {
        padding-left: 1.5rem;
        font-size: var(--step--2);
        &::before {
            width: 1.125rem;
            height: 1.125rem;
            top: 0.75em;
            transform: translateY(-50%);
        }
    }
    // &:checked {
    //     + label {
    //         color: $primary;
    //         &::before {
    //             border-color: $primary;
    //         }
    //     }
    // }
}
.is-checkradio[type="checkbox"] + label::after {
    top: 0.2rem;
    left: 0.4rem;
}
.is-checkradio[type="radio"] + label::after {
    top: 1.45px;
    left: 1px;
    width: 1rem;
    height: 1rem;
}
