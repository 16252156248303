.iframe-container {
    width: 100%;
    height: auto;
    position: relative;
    padding-bottom: 55.56%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
