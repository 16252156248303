.calendar {
    caption {
        margin-bottom: var(--space-s);
        // display: flex;
        // gap: .5rem;
    }
    th,
    td {
        border: 0;
        text-align: center !important;
        vertical-align: middle;
    }
    td.notInMonth {
        opacity: .4;
    }
    thead button {
        background: none;
        border: 0;
    }
    .arrow {
        a {
            text-indent: -10000px;
            display: inline-block;
            width: 2rem;
            height: 1.5rem;
            text-decoration: none;
            background-position: 50%;
            background-repeat: no-repeat;
            // background-color: aqua;
        }
        &.previous a {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' xmlns='http://www.w3.org/2000/svg' width='9' height='14'%3E%3Cpath d='m6.999 14 1.633-1.632L3.267 7l5.365-5.367L7 0 0 7.001 6.999 14Z' fill-rule='evenodd'/%3E%3C/svg%3E");
        }
        &.next a {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' xmlns='http://www.w3.org/2000/svg' width='9' height='14'%3E%3Cpath d='M1.633 0 0 1.632 5.365 7 0 12.366 1.633 14l7-7.001-7-6.999Z' fill-rule='evenodd'/%3E%3C/svg%3E");
        }
    }
    .day,
    tbody a {
        background-color: $red;
        color: #fff;
        border-radius: $radius-rounded;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 2.15rem;
        height: 2.15rem;
        text-decoration: none;
        margin: 0 auto;

    }
    tbody a:hover {
        border: 1px solid #fff;
    }
    tbody tr:first-child {
        display: none;
    }
}
