:root {
    --flow: var(--space-s);
    --fluid-40-80: clamp(2.5rem, 1.6304rem + 4.3478vw, 5rem);
    --fluid-55-80: clamp(3.4375rem, 2.894rem + 2.7174vw, 5rem);
    --fluid-145-256: clamp(9.0625rem, 6.6495rem + 12.0652vw, 16rem);
    --fluid-367-714: clamp(22.9375rem, 15.394rem + 37.7174vw, 44.625rem);
    --fluid-555-1000: clamp(34.6875rem, 25.0136rem + 48.3696vw, 62.5rem);
}

// color focus
$focus-color: $blue;
// $focus-color: currentColor;
