
.instagram-video-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-top: 125%;
    height: 100%;
  }
  .instagram-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
