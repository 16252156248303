.page-header {
    background-size: cover;
    background-position: center;
    height: var(--fluid-367-714);
    .home & {
        height: var(--fluid-555-1000);
    }
    > div {
        z-index: 2;
    }
    h1,
    h2 {
        font-size: var(--fluid-40-80);
        text-shadow: 0 0 10px rgba(0,0,0,0.5);
        @include desktop {
            width: 75%;
        }
    }
    p {
        @include tablet {
            width: 30%;
        }
    }
    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        background: linear-gradient(to bottom, black, transparent);
        width: 100%;
        height: 20%;
        left: 0;
        top: 0;
        z-index: 1;
    }
    &::after {
        background: linear-gradient(to right, black, transparent);
        width: 60%;
        height: 100%;
        .home & {
            display: none;
        }
    }
    &--sub {
        height: var(--fluid-145-256);
        &::before {
            background: rgba(0, 0, 0, 0.7);
            height: 100%;
        }
        &::after {
            display: none;
        }
    }
    &--vv {
        .wrapper {
            position: relative;
            &::after {
                content: "";
                display: block;
                width: 250px;
                height: 125px;
                background: url("img/logo-visita-valls.svg");
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                @include touch {
                    transform: translateX(-50%) scale(0.75);
                    transform-origin: bottom;
                }
            }
        }
    }
}
