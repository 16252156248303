@include mobile {
    .destacats-home {
        > .column > div {
            flex-direction: row-reverse !important;
            align-items: flex-end;
            gap: 0 !important;
            > div:first-child {
                flex: none;
                width: 66.6666666667%;
                padding-left: var(--space-m);
            }
        }
        &__img {
            flex: none;
            width: 33.3333333333%;
        }
    }
}
