// .input,
// .button {
//     &.is-large {
//         font-size: var(--step-1);
//     }
// }

// Arreglar focus (node_modules/bulma-scss/utilities/_controls.scss l. 34)

// .button:active,
// .button:focus {
//     @include focus;
// }
// .file-cta:active,
// .file-cta:focus,
// .file-name:active,
// .file-name:focus,
// .input:active,
// .input:focus,
// .is-active.button,
// .is-active.file-cta,
// .is-active.file-name,
// .is-active.input,
// .is-active.textarea,
// .is-focused.button,
// .is-focused.file-cta,
// .is-focused.file-name,
// .is-focused.input,
// .is-focused.textarea,
// .select select.is-active,
// .select select.is-focused,
// .select select:active,
// .select select:focus,
// .textarea:active,
// .textarea:focus {
//     // @include focus;
//     outline: 0;
// }

// Font-family
input,
textarea,
select {
    font-family: $family-secondary;
}

// Separació de checkboxes i radiobuttons
ul.field {
    > * + * {
        margin-top: var(--space-3xs);
    }
}

// Select
.select {
    width: 100%;
    select {
        width: 100%;
        background: #fff;
        // border: 2px solid #000;
    }
    &::after {
        width: 8px !important;
        height: 15px !important;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23333' fill-rule='evenodd'%3E%3Cpath d='m4 0 4 6H0zM4 15l4-6H0z'/%3E%3C/g%3E%3C/svg%3E");
        transform: none !important;
        border: 0 !important;
    }
    select:focus {
        outline: 2px solid $focus-color;
        outline-offset: 0.25rem;
        &:not(:focus-visible) {
            outline: 0;
        }
        &:focus-visible {
            outline: 2px solid $focus-color;
            outline-offset: 0.25rem;
        }
    }
}

// Select2
.select2-container--default {
    background-color: $input-background-color;
    border-radius: $input-radius;
    height: $control-height;
    padding-bottom: $control-padding-vertical;
    padding-left: $control-padding-horizontal;
    border: 1px solid $input-border-color;
    .select2-selection--multiple {
        background-color: transparent !important;
        border-color: transparent !important;
        .select2-selection__rendered {
            padding: 0 !important;
        }
    }
    &:hover {
        border-color: $input-color;
    }
}
.select2-container .select2-search--inline .select2-search__field {
    margin-top: 12px !important;
    color: $input-color;
}

// input file retocs
.file > .file-label {
    border-radius: $input-radius;
    border: 1px solid $input-border-color;
    &:hover {
        background: $input-background-color;
        border-color: $input-color;
        .file-cta {
            background-color: transparent;
        }
    }
    // Fa visible el focus
    &:focus-within:not(:focus-visible) {
        outline: 2px solid $focus-color;
        outline-offset: 0.25rem;
    }
}

.file-cta {
    font-size: var(--step--1);
    border: 0;
    // padding-left: $control-height;
    background-color: $input-background-color;
    // &::before {
    //     content: "";
    //     display: inline-flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     font-size: var(--step--1);
    //     background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjcuNzE0IiBoZWlnaHQ9IjMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMC42NDMgMy4xNDNoNC40N2MuNTY4IDAgMS4xMTMuMjI2IDEuNTE0LjYyN2w5LjQ2IDkuNDZjLjQwMi40MDEuNjI3Ljk0Ni42MjcgMS41MTR2MTMuMDQyQTMuMjE0IDMuMjE0IDAgMCAxIDIzLjUgMzFIOS41NzFhMy4yMTQgMy4yMTQgMCAwIDEtMy4yMTQtMy4yMTR2LTguNTcyIi8+PHBhdGggZD0iTTE2IDMuNjc5djguMDM1YzAgMS4xODQuOTYgMi4xNDMgMi4xNDMgMi4xNDNoOC4wMzYiLz48cGF0aCBkPSJNNy40MjkgNC4yMTR2MTAuMTc5QTEuNTg2IDEuNTg2IDAgMCAxIDUuODIgMTZjLS44MDMgMC0xLjYwNy0uNjEtMS42MDctMS42MDdWNC43NUM0LjIxNCAyLjcwMiA1LjMyNCAxIDcuNDMgMWMyLjEwNCAwIDMuMjE0IDEuNjYgMy4yMTQgMy43MDhWMTRjMCAyLjg4LTEuODYzIDUuMjE0LTQuODIyIDUuMjE0QzIuODYzIDE5LjIxNCAxIDE2Ljg4IDEgMTRWOC41Ii8+PC9nPjwvc3ZnPg==")
    //         no-repeat 50% 50%;
    //     border-right: 3px solid #fff;
    //     height: $control-height;
    //     width: $control-height;
    //     margin-left: calc($control-height * -1);
    //     margin-right: 1em;
    // }
}
.file-name {
    // background-color: transparent;
    font-size: var(--step--2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: none;
}
.file-icon {
    width: 20px;
    height: 17px;
    svg {
        filter: brightness(0);
    }
}

// Select amb addons
.field.has-addons {
    // p.control:first-child {
    // background-color: #e3e3e3;
    // border-color: $input-border-color;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // padding: var(--space-2xs);
    // }
    &:has(select:hover) {
        input {
            border-color: $input-color;
        }
    }
    &:has(input:hover) {
        select {
            border-color: $input-color;
        }
    }
    .select {
        select {
            padding-right: 3rem;
            border: 1px solid $input-border-color;
            border-right: 0;
            &:hover {
                border-color: $input-color;
            }
        }
        &::after {
            border-color: #000 !important;
            margin-left: 0.5rem;
        }
    }
    .control:last-of-type.is-expanded {
        input {
            border-left: 0;
        }
    }
}
// select.prefix {
// background-color: $primary;
// border-color: $primary;
// }
// .protecciodades {
//     font-size: 14px;
//     .is-checkradio[type=checkbox]+label {
//         margin-bottom: 15px;
//         display: block;
//     }
// }
.form-body {
    > * + * {
        margin-top: var(--space-m);
    }
}
.protecciodades {
    p {
        font-size: 14px;
        margin-top: 10px;
    }
}
