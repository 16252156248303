.instagram-media {
    margin-bottom: 0 !important;
    min-width: auto !important;
    border-radius: 0 !important;
    &::before,
    &::after {
        display: none;
    }
}

// millora visualment la càrrega
.instagram-media-registered {
    opacity: 0;
}
body:has(iframe.instagram-media:not(.instagram-media-rendered)) {
    iframe.instagram-media {
        opacity: 0;
    }
}