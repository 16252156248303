@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 300;
    src: url(../../fonts/Fira-Sans-italic-300.ttf) format("truetype"),
        url(../../fonts/Fira-Sans-italic-300.woff) format("woff"),
        url(../../fonts/Fira-Sans-italic-300.woff2) format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 400;
    src: url(../../fonts/Fira-Sans-italic-400.ttf) format("truetype"),
        url(../../fonts/Fira-Sans-italic-400.woff) format("woff"),
        url(../../fonts/Fira-Sans-italic-400.woff2) format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    src: url(../../fonts/Fira-Sans-normal-300.ttf) format("truetype"),
        url(../../fonts/Fira-Sans-normal-300.woff) format("woff"),
        url(../../fonts/Fira-Sans-normal-300.woff2) format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: url(../../fonts/Fira-Sans-normal-400);
    src: local("Fira Sans"),
        url(../../fonts/Fira-Sans-normal-400.ttf) format("truetype"),
        url(../../fonts/Fira-Sans-normal-400.woff) format("woff"),
        url(../../fonts/Fira-Sans-normal-400?#iefix) format("embedded-opentype"),
        url(../../fonts/Fira-Sans-normal-400.woff2) format("woff2"),
        url(../../fonts/Fira-Sans-normal-400.svg#FiraSans) format("svg");
    font-display: swap;
}

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    src: url(../../fonts/Fira-Sans-normal-500.woff2) format("woff2"),
        url(../../fonts/Fira-Sans-normal-500.woff) format("woff"),
        url(../../fonts/Fira-Sans-normal-500.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 600;
    src: url(../../fonts/Fira-Sans-normal-600.ttf) format("truetype"),
        url(../../fonts/Fira-Sans-normal-600.woff) format("woff"),
        url(../../fonts/Fira-Sans-normal-600.woff2) format("woff2");
    font-display: swap;
}
