.box-info {
    h2 {
        span:last-child {
            border-left: 1px solid #000;
            padding-left: var(--space-m);
            margin-left: var(--space-m);
        }
    }
    &__dades {
        font-size: var(--step--1);
        display: table;
        width: 100%;
        > div {
            display: table-row;
            dt,
            dd {
                display: table-cell;
                padding: var(--space-xs) 0;
            }
            &:first-child {
                dt,
                dd {
                    padding-top: 0;
                }
            }
            dt {
                width: 1px;
                white-space: nowrap;
                font-family: $family-primary;
                padding-right: var(--space-l) !important;
            }
            dd {
                width: auto;
            }
            &::before {
                display: none;
            }
            + div {
                > * {
                    border-top: 1px solid $grey;
                }
                dd > * + * {
                    margin-top: var(--space-xs);
                }
            }
        }
    }
    &__content {
        display: table;
        width: 100%;
        > * {
            display: table-row;
            > * {
                display: table-cell;
                &:first-child {
                    width: 1px;
                    white-space: nowrap;
                    padding-right: var(--space-m);
                }
                &:last-child {
                    width: auto;
                }
            }
        }
    }
    &--border > div {
        &::before {
            content: "";
            display: block;
            width: 1px;
            height: 3.25em;
            background-color: #000;
            position: absolute;
            left: 0;
        }
    }
}
